import {createContext} from 'react';

type Page = {
  path: string,
  title: string,
};

export type AllPagesContext = {
  pages: string[],
  paths: string[],
  pagesByPage: {[page: string]: Page},
  pagesByPath: {[path: string]: Page},
  findPageByPage(page: string): {path: string, title: string} | undefined,
  findPageByPath(path: string): {path: string, title: string} | undefined,
};

export const AllPagesContext = createContext<AllPagesContext>(undefined as any);
