import preval from 'babel-plugin-preval/macro';
import type {colors as colorsNode} from './colors-node.js';

export const colors: typeof colorsNode = preval`
  const {colors} = require('./colors-node.js');

  module.exports = colors;
`;

// export const colors = colorsNode;

// if (process.env.NODE_ENV === 'development') {
//   colorsExport = colorsNode;
// }

// export const colors = colorsExport;
