module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"/opt/build/repo/website-gatsby/static/media/profile-picture-round.png","name":"emanueleperuffo","short_name":"emanueleperuffo","start_url":"/","lang":"it","background_color":"#ffffff","theme_color":"#000000","display":"minimal-ui","localize":[{"lang":"en","start_url":"/en/"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f164b776756f9c41180ac03deeee8906"},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/styles/typography-node","omitGoogleFont":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-ZGB95PJ74S"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/build/repo/website-gatsby/src/layouts/GatsbyLayout.tsx"},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"manualInit":true,"enableIdentityWidget":false,"modulePath":"/opt/build/repo/website-gatsby/src/cms/cms.tsx"},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#944aa0","showSpinner":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
