import {createContext} from 'react';
import {Lightbox} from '../types/Lightbox';

export type LightboxContext = {
  register(lightbox: Lightbox, id?: string): void,
  unregister(lightbox: Lightbox): void,
  open(lightbox: Lightbox): void,
  close(): void,
};

export const LightboxContext = createContext<LightboxContext>(undefined as any);
