import {FluidObject} from 'gatsby-image';
import {createContext} from 'react';
import {Bicycle} from '../types/Bicycle';
import {GeoJSONTrack} from '../types/GeoJSONTrack';

export type CurrentPageContext = {
  data: any,
  type: string,
  page: string,
  title: string,
  path: string,
  same: {path: string, locale: string}[],
  prev?: {title: string, path: string},
  next?: {title: string, path: string},
  breadcrumbs: {label: string, path?: string}[],
  headings: {value: string, depth: number}[],
  createdAt?: Date,
  updatedAt?: Date,
  featuredImage?: {
    original: {src: string},
    page: FluidObject,
    seo: {src: string},
  },
  posts?: {
    title: string,
    path: string,
    createdAt: Date,
    excerpt: string,
    thumbnail?: {
      posts: FluidObject,
    },
  }[],
  commentsEnabled?: boolean,
  commentsForm?: boolean,
  comments: {
    _id: string,
    _parent: string,
    locale: string,
    createdAt: Date,
    name: string,
    email: string,
    website?: string,
    body: string,
  }[],
  track?: {
    gpx: string,
    kml: string,
    geojson: GeoJSONTrack,
    map: {
      track: FluidObject,
    },
    bicycles?: Bicycle[],
    links?: {
      link: string,
      label: string,
    }[],
    duration?: string,
    // TODO: road surface
    // TODO: time
    // TODO: difficulty
  },
};

export const CurrentPageContext = createContext<CurrentPageContext>(undefined as any);
