import {AnimatedCssGetter} from '../types/AnimatedCssGetter';

export const animationDuration = 300;
export const bigAnimationDuration = 500;
export const loadingAnimationDuration = 2500;
export const linkTransitionDuration = 125;
export const focusTransitionDuration = 175;

export const alertAnimationDuration = animationDuration;

export const alertAnimatedCss: AnimatedCssGetter = (css) => ({
  enter: css({
    opacity: 0,
  }),
  enterActive: css({
    opacity: 1,
    transition: `opacity ${animationDuration}ms ease-out`,
  }),
  exit: css({
    opacity: 1,
    transition: `opacity ${animationDuration}ms ease-out`,
  }),
  exitActive: css({
    opacity: 0,
  }),
});

export const modalAnimatedCss: AnimatedCssGetter = (css) => ({
  enter: css({
    opacity: 0,
  }),
  enterActive: css({
    opacity: 1,
    transition: `opacity ${animationDuration}ms ease-out`,
  }),
  exit: css({
    opacity: 0,
    transition: `opacity ${animationDuration}ms ease-out`,
  }),
});
