import {HistoryLocation} from '@reach/router';

export const trackPageView = (location: HistoryLocation) => {
  // https://github.com/gatsbyjs/gatsby/blob/f14c99b80b77f0d9094c1521976ec8e805aaa87f/packages/gatsby-plugin-google-analytics/src/gatsby-browser.js#L1

  if (process.env.NODE_ENV !== 'production' || typeof gtag !== 'function') {
    return;
  }

  const pathIsExcluded = location
    && typeof excludeGtagPaths !== 'undefined'
    && excludeGtagPaths.some((rx: any) => rx.test(location.pathname));

  if (pathIsExcluded) {
    return;
  }

  // wrap inside a timeout to make sure react-helmet is done with its changes (https://github.com/gatsbyjs/gatsby/issues/11592)
  const sendPageView = () => {
    const pagePath = location
      ? location.pathname + location.search + location.hash
      : undefined;

    gtag('event', 'page_view', {page_path: pagePath});
  };

  if ('requestAnimationFrame' in window) {
    requestAnimationFrame(() => {
      requestAnimationFrame(sendPageView);
    });
  } else {
    // simulate 2 rAF calls
    setTimeout(sendPageView, 32);
  }

  // const pagePath = location
  //   ? location.pathname + location.search + location.hash
  //   : undefined;

  // (window as any).ga('set', 'page', pagePath);
  // (window as any).ga('send', 'pageview');
};
