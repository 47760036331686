import {Lightbox} from '../../types/Lightbox';

export const getLightboxFragment = (lightbox: Lightbox, id: string | undefined) => {
  const fragmentGroup = typeof lightbox.group === 'string'
    ? lightbox.group
    : Number(lightbox.group);

  return `lb--${id || ''}--${fragmentGroup}--${lightbox.src}`;
};

export const parseLightboxFragment = (fragment: string) => {
  const matches = fragment.match(/^#?lb--.*--(.+)--(.+)$/);

  if (matches) {
    const [, fragmentGroup, src] = matches;

    const group = !isNaN(Number(fragmentGroup))
      ? Boolean(Number(fragmentGroup))
      : fragmentGroup;

    return {group, src};
  }
};
