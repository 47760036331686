module.exports.fontFamilyArrayToString = (fontFamily) => fontFamily.map((font) => `"${font}"`).join(', ');

module.exports.googleFonts = [
  // {name: 'Lato', styles: ['300', '400', '600', '700', '900']},
  {name: 'Mukta', styles: ['300', '400', '700']},
  // {name: 'Nunito', styles: ['300', '400', '600', '700']},
  // {name: 'Rubik', styles: ['300', '400', '600', '700']},
  // {name: 'Nunito Sans', styles: ['300', '400', '600', '700']},
  // {name: 'Exo 2', styles: ['300', '400', '600', '700']},
  // {name: 'Crimson Text', styles: ['400', '400i', '600', '600i', '700', '700i']},
  // {name: 'Libre Baskerville', styles: ['400', '400i', '600']},
  // {name: 'EB Garamond', styles: ['400', '400i', '600', '600i']},
  {name: 'Montserrat', styles: ['400', '600'/* , '700' */, /* '700', '800', */ '800'/* , '900' */]},
  // {name: 'Poppins', styles: ['400', '600', '700', '800', '900']},
  // {name: 'Merriweather', styles: ['300', '400', '600', '900']},
  // {name: 'Markazi Text', styles: ['400']},
  // {name: 'PT Serif', styles: ['400']},
  // {name: 'Lora', styles: ['400']},
  // {name: 'EB Garamond', styles: ['400']},
  // {name: 'Song Myung', styles: ['400']},
];

module.exports.headerFontFamily = [
  // 'Exo 2',
  'Montserrat',
  '-apple-system',
  'BlinkMacSystemFont',
  'Segoe UI',
  'Roboto',
  'Oxygen',
  'Ubuntu',
  'Cantarell',
  'Fira Sans',
  'Droid Sans',
  'Helvetica Neue',
  'Arial',
  'sans-serif',
];

module.exports.bodyFontFamily = [
  // 'Exo 2',
  'Mukta',
  '-apple-system',
  'BlinkMacSystemFont',
  'Segoe UI',
  'Roboto',
  'Oxygen',
  'Ubuntu',
  'Cantarell',
  'Fira Sans',
  'Droid Sans',
  'Helvetica Neue',
  'Arial',
  'sans-serif',
];

module.exports.uiFontFamily = [
  'Montserrat',
  '-apple-system',
  'BlinkMacSystemFont',
  'Segoe UI',
  'Roboto',
  'Oxygen',
  'Ubuntu',
  'Cantarell',
  'Fira Sans',
  'Droid Sans',
  'Helvetica Neue',
  'Arial',
  'sans-serif',
];

module.exports.monospaceFontFamily = [
  // 'Space Mono',
  'SFMono-Regular',
  'Menlo',
  'Monaco',
  'Consolas',
  'Liberation Mono',
  'Courier New',
  'monospace',
];

module.exports.emojiFontFamily = [
  'apple color emoji',
  'segoe ui emoji',
  'noto color emoji',
  'android emoji',
  'emojisymbols',
  'emojione mozilla',
  'twemoji mozilla',
  'segoe ui symbol',
];
