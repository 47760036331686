import {css, keyframes} from '@emotion/core';
import {WrapPageElementBrowserArgs, WrapPageElementNodeArgs} from 'gatsby';
import {useEffect, useRef} from 'react';
import {LightboxProvider} from '../contexts/LightboxProvider';
import {animationDuration, bigAnimationDuration} from '../styles/animations';
import {AnimatedCssGetter} from '../types/AnimatedCssGetter';

// const globalStyles: GlobalProps<unknown>['styles'] = {
//   html: {
//     // overflow: 'visible',
//     // background: 'black',
//   },
// };

const gatsbyLayoutCss = css({
  position: 'relative',
  minHeight: ['100vh', 'var(--window-height, 100vh)'],
  // overflowY: 'scroll',
  // perspective: '100px',
});

const mainAnimation = keyframes({
  from: {opacity: 0},
  to: {opacity: 1},
});

const pageCss = css({
  minHeight: ['100vh', 'var(--window-height, 100vh)'],
  animation: `${mainAnimation} ${animationDuration}ms ease-out`,
  animationDelay: `-${animationDuration}ms`,
});

const animatedPageCss = css({
  animationDelay: '0ms',
});

const forwardPageTransitionAnimatedCss: AnimatedCssGetter = (css) => ({
  enter: css({
    // transform: 'perspective(100vh) translateZ(-100px)',
    opacity: 0,

    zIndex: 100,
    position: 'absolute',
    top: 0,
    left: 0,
    height: ['100vh', 'var(--window-height, 100vh)'],
    width: '100%',
  }),
  enterActive: css({
    // transform: 'perspective(100vh) translateZ(0)',
    // height: (typography.adjustFontSizeTo('80%') as any).lineHeight,
    opacity: 1,
    transition: `transform ${bigAnimationDuration}ms ease, opacity ${bigAnimationDuration}ms ease`,

    zIndex: 100,
    position: 'absolute',
    top: 0,
    left: 0,
    height: ['100vh', 'var(--window-height, 100vh)'],
    width: '100%',
  }),
  exit: css({
    // transform: 'translateY(0)',
    opacity: 1,

    zIndex: 101,
    position: 'absolute',
    top: 0,
    left: 0,
    height: ['100vh', 'var(--window-height, 100vh)'],
    width: '100%',
  }),
  exitActive: css({
    // transform: 'translateY(100vh)',
    opacity: 0,
    transition: `transform ${bigAnimationDuration}ms ease, opacity ${bigAnimationDuration}ms ease`,

    zIndex: 101,
    position: 'absolute',
    top: 0,
    left: 0,
    height: ['100vh', 'var(--window-height, 100vh)'],
    width: '100%',
  }),
});

const backwardPageTransitionAnimatedCss: AnimatedCssGetter = (css) => ({
  enter: css({
    // transform: 'translateY(100vh)',
    opacity: 0,

    zIndex: 101,
    position: 'absolute',
    top: 0,
    left: 0,
    height: ['100vh', 'var(--window-height, 100vh)'],
    width: '100%',
  }),
  enterActive: css({
    // transform: 'translateY(0)',
    opacity: 1,
    transition: `transform ${bigAnimationDuration}ms ease, opacity ${bigAnimationDuration}ms ease`,

    zIndex: 101,
    position: 'absolute',
    top: 0,
    left: 0,
    height: ['100vh', 'var(--window-height, 100vh)'],
    width: '100%',
  }),
  exit: css({
    // transform: 'perspective(100px) translateZ(0)',
    opacity: 1,

    zIndex: 100,
    position: 'absolute',
    top: 0,
    left: 0,
    height: ['100vh', 'var(--window-height, 100vh)'],
    width: '100%',
  }),
  exitActive: css({
    // transform: 'perspective(100px) translateZ(-10px)',
    // height: (typography.adjustFontSizeTo('80%') as any).lineHeight,
    opacity: 0,
    transition: `transform ${bigAnimationDuration}ms ease, opacity ${bigAnimationDuration}ms ease`,

    zIndex: 100,
    position: 'absolute',
    top: 0,
    left: 0,
    height: ['100vh', 'var(--window-height, 100vh)'],
    width: '100%',
  }),
});

const forwardSamePageTransitionAnimatedCss: AnimatedCssGetter = (css) => ({
  enter: css({
    transform: 'perspective(100vw) rotateY(-180deg)',
    // opacity: 0,

    position: 'absolute',
    top: 0,
    left: 0,
    height: ['100vh', 'var(--window-height, 100vh)'],
    width: '100%',
    // transformStyle: 'preserve-3d',
    // backfaceVisibility: 'hidden',
  }),
  enterActive: css({
    transform: 'perspective(100vw) rotateY(-360deg)',
    // opacity: 1,
    transition: `transform ${bigAnimationDuration}ms ease, opacity ${bigAnimationDuration}ms ease`,

    position: 'absolute',
    top: 0,
    left: 0,
    height: ['100vh', 'var(--window-height, 100vh)'],
    width: '100%',
    // transformStyle: 'preserve-3d',
    // backfaceVisibility: 'hidden',
  }),
  exit: css({
    transform: 'perspective(100vw) rotateY(-0deg)',
    // opacity: 1,

    position: 'absolute',
    top: 0,
    left: 0,
    height: ['100vh', 'var(--window-height, 100vh)'],
    width: '100%',
    // transformStyle: 'preserve-3d',
    backfaceVisibility: 'hidden',
  }),
  exitActive: css({
    transform: 'perspective(100vw) rotateY(-180deg)',
    // height: (typography.adjustFontSizeTo('80%') as any).lineHeight,
    // opacity: 0,
    transition: `transform ${bigAnimationDuration}ms ease, opacity ${bigAnimationDuration}ms ease`,

    position: 'absolute',
    top: 0,
    left: 0,
    height: ['100vh', 'var(--window-height, 100vh)'],
    width: '100%',
    // transformStyle: 'preserve-3d',
    backfaceVisibility: 'hidden',
  }),
});

const backwardSamePageTransitionAnimatedCss: AnimatedCssGetter = (css) => ({
  enter: css({
    // visibility: 'hidden',
    transform: 'perspective(100vh) rotateY(180deg)',
    // opacity: 0,

    position: 'absolute',
    top: 0,
    left: 0,
    height: ['100vh', 'var(--window-height, 100vh)'],
    width: '100%',
    // transformStyle: 'preserve-3d',
    // backfaceVisibility: 'hidden',
  }),
  enterActive: css({
    // visibility: 'hidden',
    transform: 'perspective(100vh) rotateY(360deg)',
    // height: (typography.adjustFontSizeTo('80%') as any).lineHeight,
    // opacity: 1,
    transition: `transform ${bigAnimationDuration}ms ease, opacity ${bigAnimationDuration}ms ease`,

    position: 'absolute',
    top: 0,
    left: 0,
    height: ['100vh', 'var(--window-height, 100vh)'],
    width: '100%',
    // transformStyle: 'preserve-3d',
    // backfaceVisibility: 'hidden',
  }),
  exit: css({
    // visibility: 'hidden',
    transform: 'perspective(100vh) rotateY(0deg)',
    // opacity: 1,

    position: 'absolute',
    top: 0,
    left: 0,
    height: ['100vh', 'var(--window-height, 100vh)'],
    width: '100%',
    // transformStyle: 'preserve-3d',
    backfaceVisibility: 'hidden',
  }),
  exitActive: css({
    // visibility: 'hidden',
    transform: 'perspective(100vh) rotateY(180deg)',
    // opacity: 0,
    transition: `transform ${bigAnimationDuration}ms ease, opacity ${bigAnimationDuration}ms ease`,

    position: 'absolute',
    top: 0,
    left: 0,
    height: ['100vh', 'var(--window-height, 100vh)'],
    width: '100%',
    // transformStyle: 'preserve-3d',
    backfaceVisibility: 'hidden',
  }),
});

type Props = (WrapPageElementBrowserArgs | WrapPageElementNodeArgs)['props'] & {
  children: (WrapPageElementBrowserArgs | WrapPageElementNodeArgs)['element'],
};

export default (props: Props) => {
  const {children, location: {key}, data, pageContext, uri} = props;

  // const prevKeyRef = useRef(key);
  // const prevPageContextRef = useRef(pageContext);

  // const [duration, getter] = useMemo(
  //   () => {
  //     const isSame = Boolean(pageContext.id)
  //       && Boolean(prevPageContextRef.current.same)
  //       && prevPageContextRef.current.same.includes(pageContext.id);

  //     const prevKey = prevKeyRef.current && prevKeyRef.current !== 'initial' ? prevKeyRef.current : '0';
  //     const currentKey = key && key !== 'initial' ? key : '0';

  //     prevKeyRef.current = key;
  //     prevPageContextRef.current = pageContext;

  //     if (isSame) {
  //       if (currentKey < prevKey) {
  //         return [bigAnimationDuration, backwardSamePageTransitionAnimatedCss];
  //       }

  //       return [bigAnimationDuration, forwardSamePageTransitionAnimatedCss];
  //     }

  //     if (currentKey < prevKey) {
  //       return [animationDuration, backwardPageTransitionAnimatedCss];
  //     }

  //     return [animationDuration, forwardPageTransitionAnimatedCss];
  //   },
  //   [key, data, pageContext],
  // );

  // const childFactory = useCallback(
  //   (child: React.ReactElement) => (
  //     <ClassNames key={child.key as any}>
  //       {({css}) => cloneElement(child, {...child.props, classNames: getter(css)})}
  //     </ClassNames>
  //   ),
  //   [getter],
  // );

  const animateRef = useRef(false);

  useEffect(
    () => { animateRef.current = true; },
    [],
  );

  return (
    <div css={[gatsbyLayoutCss]}>
      {/* <Global styles={globalStyles} /> */}

      <LightboxProvider>
        <div css={[pageCss, animateRef.current && animatedPageCss]} key={uri}>
          {children}
        </div>
      </LightboxProvider>

      {/* <TransitionGroup
        component={null}
        childFactory={childFactory}
      >
        <CSSTransition
          key={uri}
          timeout={duration}
          mountOnEnter
          unmountOnExit
        >
          <div css={pageCss}>
            {children}
          </div>
        </CSSTransition>
      </TransitionGroup> */}
    </div>
  );
};
