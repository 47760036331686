import preval from 'babel-plugin-preval/macro';

export const {
  font500,
  font400,
  font300,
  font250,
  font200,
  font150,
  font100,
  font80,
  font60,
  font50,

  rhythm25,
  rhythm15,
  rhythm13,
  rhythm12,
  rhythm10,
  rhythm5,
  rhythm4,
  rhythm3,
  rhythm2,
  rhythm1_5,
  rhythm1_25,
  rhythm1,
  rhythm0_75,
  rhythm0_5,
  rhythm0_375,
  rhythm0_25,
  rhythm0_125,

  typographyStyles,
} = preval`
  const {pick} = require('lodash');

  module.exports = pick(require('./typography-node.js'), [
    'font500',
    'font400',
    'font300',
    'font250',
    'font200',
    'font150',
    'font100',
    'font80',
    'font60',
    'font50',

    'rhythm25',
    'rhythm15',
    'rhythm13',
    'rhythm12',
    'rhythm10',
    'rhythm5',
    'rhythm4',
    'rhythm3',
    'rhythm2',
    'rhythm1_5',
    'rhythm1_25',
    'rhythm1',
    'rhythm0_75',
    'rhythm0_5',
    'rhythm0_375',
    'rhythm0_25',
    'rhythm0_125',

    'typographyStyles',
  ]);
`;
