import {useCallback, useEffect, useMemo, useState} from 'react';
import {BehaviorSubject} from 'rxjs';
import {UserConsentContext} from './UserConsentContext';

type Props = {
  userConsentSubject: BehaviorSubject<UserConsentContext['userConsent']>,
  children: React.ReactNode,
};

export const UserConsentProvider = (props: Props) => {
  const {userConsentSubject, children} = props;

  const [userConsent, setUserConsent] = useState<UserConsentContext['userConsent']>(userConsentSubject.value);

  useEffect(
    () => {
      const subscription = userConsentSubject.subscribe({
        next: setUserConsent,
      });

      return () => { subscription.unsubscribe(); };
    },
    [userConsentSubject],
  );

  const allow = useCallback(
    () => { userConsentSubject.next('allowed'); },
    [userConsentSubject],
  );

  const context = useMemo(
    () => ({
      allow,
      userConsent,
    }),
    [allow, userConsent],
  );

  return (
    <UserConsentContext.Provider value={context}>
      {children}
    </UserConsentContext.Provider>
  );
};
