import {ClassNames} from '@emotion/core';
import {useLocation} from '@reach/router';
import {navigate} from 'gatsby';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import {LightboxModal} from '../components/LightboxModal';
import {getLightboxFragment, parseLightboxFragment} from '../components/Link';
import {animationDuration, modalAnimatedCss} from '../styles/animations';
import {Lightbox} from '../types/Lightbox';
import {LightboxContext} from './LightboxContext';

type Props = {
  children: React.ReactNode;
};

export const LightboxProvider = (props: Props) => {
  const {children} = props;

  const [lightbox, setLightbox] = useState<{src: string, group: Lightbox['group']}>();

  const [lightboxes, setLightboxes] = useState<{lightbox: Lightbox, id?: string}[]>([]);

  const location = useLocation();

  useEffect(
    () => {
      setLightbox((prev) => {
        const parsed = parseLightboxFragment(location.hash);

        if (prev && !parsed) {
          return undefined;
        }

        if (!prev && parsed) {
          return parsed;
        }

        return prev;
      });
    },
    [location],
  );

  // useEffect(
  //   () => {
  //     const handler = (e: PopStateEvent) => {
  //       if (e.state?.lightbox) {
  //         setLightbox(undefined);
  //       }
  //     };

  //     window.addEventListener('popstate', handler, {once: true});

  //     return () => { window.removeEventListener('popstate', handler); };
  //   },
  //   [lightbox],
  // );

  const register = useCallback(
    (lightbox: Lightbox, id?: string) => {
      setLightboxes((prev) => [...prev, {lightbox, id}]);
    },
    [],
  );

  const unregister = useCallback(
    (lightbox) => {
      setLightboxes((prev) => prev.filter(
        (it) => it.lightbox !== lightbox,
      ));
    },
    [],
  );

  const open = useCallback(
    (lightbox: Lightbox) => {
      const {id} = lightboxes.find((it) => it.lightbox === lightbox)!;

      navigate(`${location.pathname}${location.search}#${getLightboxFragment(lightbox, id)}`, {
        state: {disableScrollUpdate: true},
      });
    },
    [location, lightboxes],
  );

  const groupLightboxes = useMemo(
    () => {
      if (!lightbox) {
        return [];
      }

      if (!lightbox.group) {
        return [lightboxes.find((it) => `${it.lightbox.group}` === `${lightbox.group}`)!.lightbox];
      }

      return lightboxes
        .filter((it) => it.lightbox.group === lightbox.group)
        .map((it) => it.lightbox);
    },
    [lightbox, lightboxes],
  );

  const lightboxIndex = useMemo(
    () => lightbox && groupLightboxes.findIndex((it) => it.src === lightbox.src && it.group === lightbox.group),
    [lightbox, groupLightboxes],
  );

  const close = useCallback(
    () => {
      navigate(-1);
    },
    [],
  );

  const lightboxContext: LightboxContext = useMemo(
    () => ({
      register,
      unregister,
      open,
      close,
    }),
    [register, unregister, open, close],
  );

  return (
    <LightboxContext.Provider value={lightboxContext}>
      {children}

      <ClassNames>
        {({css}) => (
          <TransitionGroup component={null}>
            {lightboxIndex !== undefined && lightboxIndex > -1 && (
              <CSSTransition
                timeout={animationDuration}
                classNames={modalAnimatedCss(css)}
              >
                <LightboxModal
                  index={lightboxIndex}
                  lightboxes={groupLightboxes}
                />
              </CSSTransition>
            )}
          </TransitionGroup>
        )}
      </ClassNames>
    </LightboxContext.Provider>
  );
};
