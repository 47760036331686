import {memo} from 'react';
import {LightboxLink as LightboxLinkComponent} from './LightboxLink';
import {Link as LinkComponent, Props} from './Link';

export {isExternalLink} from './isExternalLink';
export {getLightboxFragment, parseLightboxFragment} from './lightboxFragment';

export type LinkProps = Props;
export const Link = memo(LinkComponent);
export const LightboxLink = memo(LightboxLinkComponent);
