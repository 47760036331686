import {useCallback, useContext, useEffect} from 'react';
import {Link, LinkProps} from '.';
import {LightboxContext} from '../../contexts/LightboxContext';
import {Lightbox} from '../../types/Lightbox';
import {getLightboxFragment} from './lightboxFragment';

type Props = Exclude<LinkProps, 'href' | 'title'> & {
  lightbox: Lightbox,
};

export const LightboxLink = (props: Props) => {
  const {lightbox, id, ...linkProps} = props;

  const {open, register, unregister} = useContext(LightboxContext);

  const handleClickLink = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();

      open(lightbox);
    },
    [open, lightbox],
  );

  useEffect(
    () => {
      register(lightbox, id);

      return () => { unregister(lightbox); };
    },
    [lightbox, id, register, unregister],
  );

  return (
    <Link
      {...linkProps}
      id={getLightboxFragment(lightbox, id)}
      href={lightbox.src}
      title={lightbox.title}
      target="_blank"
      rel="noopener"
      onClick={handleClickLink}
    />
  );
};

LightboxLink.whyDidYouRender = true;
