import {ClassNames} from '@emotion/core';
import {faCog} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useEffect, useState} from 'react';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import {alertAnimatedCss, animationDuration} from '../../styles/animations';

type Props = {
  isLoading: boolean,
  timeout?: number,
  className?: string,
};

export const Loader = (props: Props) => {
  const {isLoading, className, timeout = 500} = props;

  const [show, setShow] = useState(false);

  useEffect(
    () => {
      if (isLoading) {
        const t = setTimeout(
          () => { setShow(true); },
          timeout,
        );

        return () => { clearTimeout(t); };
      }

      setShow(false);
    },
    [isLoading, timeout],
  );

  return (
    <ClassNames>
      {({css}) => (
        <TransitionGroup component={null}>
          {show && (
            <CSSTransition
              timeout={animationDuration}
              classNames={alertAnimatedCss(css)}
            >
              <div className={className}>
                <FontAwesomeIcon icon={faCog} fixedWidth spin />
              </div>
            </CSSTransition>
          )}
        </TransitionGroup>
      )}
    </ClassNames>
  );
};
