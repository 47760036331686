import {css} from '@emotion/core';
import {faExternalLinkAlt, faSearchMinus, faSearchPlus, faTimes} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import loadable from '@loadable/component';
import {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {Portal} from 'react-portal';
import {LightboxContext} from '../../contexts/LightboxContext';
import {animationDuration} from '../../styles/animations';
import {rhythm0_25} from '../../styles/typography';
import {Lightbox} from '../../types/Lightbox';
import {Loader} from '../Loader';
import {Overlay} from '../Overlay';
import {LightboxModalContentHandler} from './LightboxModalContent';

const LightboxModalContent = loadable(async () => {
  const {LightboxModalContent} = await import('./LightboxModalContent');

  return LightboxModalContent;
});

const lightboxModalCss = css({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  overflow: 'hidden',
  zIndex: 100,
  // padding: typography.rhythm(0.5),
  display: 'flex',
  flexDirection: 'column',
  gap: rhythm0_25,
});

const contentCss = css({
  overflow: 'hidden',
  height: '100%',
  color: '#fff',
  position: 'relative',

  '&:focus': {
    outline: 'none',
  },
});

const loaderCss = css({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const thumbnailsCss = css({
  flexShrink: 0,

  position: 'relative',
  height: '50px',
  overflow: 'auto',
  scrollbarWidth: 'none',

  '&::-webkit-scrollbar': {
    display: 'none',
  },
});

const thumbnailCss = css({
  display: 'inline-flex',
  marginLeft: rhythm0_25,
  height: '100%',
  alignItems: 'center',
  verticalAlign: 'bottom',
  overflow: 'hidden',

  '&:first-child /* emotion-disable-server-rendering-unsafe-selector-warning-please-do-not-use-this-the-warning-exists-for-a-reason */': {
    marginLeft: 0,
  },

  '& img': {
    filter: 'brightness(0.5)',
    // transform: 'scale(0.9)',
    transition: `filter ${animationDuration}ms ease-out`,
    maxHeight: '100%',
  },

  '&:hover': {
    '& img': {
      filter: 'brightness(1)',
      // transform: 'scale(1)',
    },
  },
});

const selectedThumbnailCss = css({
  '& img': {
    filter: 'brightness(1)',
    transform: 'scale(1)',
    maxHeight: '50px',
  },
});

const thumbnailsWrapperCss = css({
  position: 'absolute',
  left: 0,
  top: 0,
  height: '100%',
  whiteSpace: 'nowrap',
  minWidth: '100%',
  textAlign: 'center',
});

const actionsCss = css({
  textAlign: 'right',
  padding: `${rhythm0_25} ${rhythm0_25} 0 ${rhythm0_25}`,
});

const topButtonCss = css({
  color: 'white',
  background: 'none',
  appearance: 'none',
  border: 'none',
  cursor: 'pointer',
  outline: 'none',
  padding: '0',
  marginLeft: rhythm0_25,

  '&:first-child /* emotion-disable-server-rendering-unsafe-selector-warning-please-do-not-use-this-the-warning-exists-for-a-reason */': {
    marginLeft: 0,
  },
});

// const goToPrevCss = css({
//   // height: '100%',
//   display: 'flex',
//   alignItems: 'center',
//   opacity: 0,
//   position: 'absolute',
//   left: 0,
//   top: `calc(50% - ${typography.rhythm(1)})`,

//   '& a': {
//     color: 'white',
//     cursor: 'default',
//   },

//   '& svg': {
//     filter: 'drop-shadow(0 0 2px black)'
//   },

//   transition: `opacity ${animationDuration}ms ease-out`,
// });

// const visibleGoToPrevCss = css({
//   opacity: 0.5,

//   '& a': {
//     cursor: 'pointer',
//   },
// });

// const goToNextCss = css({
//   // height: '100%',
//   display: 'flex',
//   alignItems: 'center',
//   opacity: 0,
//   position: 'absolute',
//   right: 0,
//   top: `calc(50% - ${typography.rhythm(1)})`,

//   '& a': {
//     color: 'white',
//     cursor: 'default',
//   },

//   '& svg': {
//     filter: 'drop-shadow(0 0 2px black)'
//   },

//   transition: `opacity ${animationDuration}ms ease-out`,
// });

// const visibleGoToNextCss = css({
//   opacity: 0.5,

//   '& a': {
//     cursor: 'pointer',
//   },
// });

type Props = {
  index: number,
  lightboxes: Lightbox[],
};

export const LightboxModal = (props: Props) => {
  const {index, lightboxes} = props;

  const [currentLightboxIndex, setCurrentLightboxIndex] = useState(index);

  const thumbnailsRef = useRef<HTMLDivElement>(null);

  const setupThumbnails = useCallback(
    (el: HTMLDivElement | null) => {
      thumbnailsRef.current = el;

      el?.addEventListener(
        'wheel',
        (e) => {
          el!.scrollTo({
            left: el!.scrollLeft + (e.deltaX || e.deltaY),
          });

          e.stopPropagation();
          e.preventDefault();
        },
        {passive: false},
      );
    },
    [],
  );

  useEffect(
    () => {
      if (thumbnailsRef.current) {
        const thumbnailEl = thumbnailsRef.current.childNodes[0].childNodes[currentLightboxIndex] as HTMLElement;

        thumbnailsRef.current.style.scrollBehavior = 'smooth';

        thumbnailsRef.current.scrollTo({
          left: thumbnailEl.offsetLeft - thumbnailsRef.current!.offsetWidth / 2 + thumbnailEl.scrollWidth / 2,
        });

        thumbnailsRef.current.style.scrollBehavior = 'auto';
      }
    },
    [currentLightboxIndex],
  );

  const {close: closeLightbox} = useContext(LightboxContext);

  useEffect(
    () => {
      const handler = (e: KeyboardEvent) => {
        if (e.keyCode === 27) {
          e.preventDefault();

          closeLightbox();
        } else if (e.keyCode === 37) {
          e.preventDefault();

          setCurrentLightboxIndex((prev) => Math.max(prev - 1, 0));
        } else if (e.keyCode === 39) {
          e.preventDefault();

          setCurrentLightboxIndex((prev) => Math.min(prev + 1, lightboxes.length - 1));
        }
      };

      document.addEventListener('keydown', handler);

      return () => { document.removeEventListener('keydown', handler); };
    },
    [closeLightbox, lightboxes],
  );

  const handleClickThumbnail = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();

      setCurrentLightboxIndex(Number(e.currentTarget.dataset.index));
    },
    [],
  );

  const stopPropagation = useCallback(
    (e: React.BaseSyntheticEvent) => {
      e.stopPropagation();
    },
    [],
  );

  // const preventDefault = useCallback(
  //   (e: React.BaseSyntheticEvent) => {
  //     e.preventDefault();
  //   },
  //   [],
  // );

  const setupModal = useCallback(
    (el: HTMLDivElement | null) => {
      el?.addEventListener(
        'wheel',
        (e) => {
          e.stopPropagation();
          e.preventDefault();
        },
        {passive: false},
      );

      el?.addEventListener(
        'animationend',
        () => {
          if (thumbnailsRef.current) {
            const thumbnailEl = thumbnailsRef.current.childNodes[0].childNodes[currentLightboxIndex] as HTMLElement;

            thumbnailsRef.current.style.scrollBehavior = 'smooth';

            thumbnailsRef.current.scrollTo({
              left: thumbnailEl.offsetLeft - thumbnailsRef.current!.offsetWidth / 2 + thumbnailEl.scrollWidth / 2,
            });

            thumbnailsRef.current.style.scrollBehavior = 'auto';
          }
        },
        {once: true},
      );
    },
    [],
  );

  const handleClickOpenImage = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();

      window.open(lightboxes[currentLightboxIndex].src, '_blank');
    },
    [currentLightboxIndex],
  );

  const handleClickClose = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();

      closeLightbox();
    },
    [closeLightbox],
  );

  const [isLoading, setIsLoading] = useState(true);

  const modalContentHandlerRef = useRef<LightboxModalContentHandler>();

  const setModalContentHandlerRef = useCallback(
    (handler: LightboxModalContentHandler) => {
      modalContentHandlerRef.current = handler;
    },
    [],
  );

  const handleModalContentLoadStart = useCallback(
    () => { setIsLoading(true); },
    [],
  );

  const handleModalContentLoadEnd = useCallback(
    () => { setIsLoading(false); },
    [],
  );

  const handleZoomIn = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();

      modalContentHandlerRef.current?.zoomIn();
    },
    [],
  );

  const handleZoomOut = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();

      modalContentHandlerRef.current?.zoomOut();
    },
    [],
  );

  // const handleClickGoToPrev = useCallback(
  //   (e: React.MouseEvent<HTMLElement>) => {
  //     e.stopPropagation();
  //     e.preventDefault();

  //     setCurrentLightboxIndex((prev) => Math.max(prev - 1, 0));
  //   },
  //   [],
  // );

  // const handleClickGoToNext = useCallback(
  //   (e: React.MouseEvent<HTMLElement>) => {
  //     e.stopPropagation();
  //     e.preventDefault();

  //     setCurrentLightboxIndex((prev) => Math.min(prev + 1, lightboxes.length - 1));
  //   },
  //   [lightboxes],
  // );

  const handleSwipeX = useCallback(
    (swipeX: number) => {
      if (swipeX > 0) {
        setCurrentLightboxIndex((prev) => Math.max(prev - 1, 0));
      } else if (swipeX < 0) {
        setCurrentLightboxIndex((prev) => Math.min(prev + 1, lightboxes.length - 1));
      }
    },
    [lightboxes],
  );

  return (
    <Portal>
      <div>
        <Overlay onClick={closeLightbox} />

        <div css={lightboxModalCss} onClick={closeLightbox} ref={setupModal}>
          <div css={actionsCss}>
            <button type="button" css={topButtonCss} onClick={handleZoomIn}>
              <FontAwesomeIcon icon={faSearchPlus} fixedWidth />
            </button>

            <button type="button" css={topButtonCss} onClick={handleZoomOut}>
              <FontAwesomeIcon icon={faSearchMinus} fixedWidth />
            </button>

            <button type="button" css={topButtonCss} onClick={handleClickOpenImage}>
              <FontAwesomeIcon icon={faExternalLinkAlt} fixedWidth />
            </button>

            <button type="button" css={topButtonCss} onClick={handleClickClose}>
              <FontAwesomeIcon icon={faTimes} fixedWidth />
            </button>
          </div>

          <div css={contentCss}>
            <LightboxModalContent
              ref={setModalContentHandlerRef}
              lightbox={lightboxes[currentLightboxIndex]}
              onSwipeX={handleSwipeX}
              onLoadStart={handleModalContentLoadStart}
              onLoadEnd={handleModalContentLoadEnd}
            />

            <Loader css={loaderCss} isLoading={isLoading} />

            {/* <div css={[goToPrevCss, currentLightboxIndex > 0 && visibleGoToPrevCss]} >
              <a target="_blank" rel="noopener" href={lightboxes[Math.max(currentLightboxIndex - 1, 0)].src} onClick={handleClickGoToPrev}>
                <FontAwesomeIcon icon={faAngleLeft} fixedWidth />
              </a>
            </div>

            <div css={[goToNextCss, currentLightboxIndex < lightboxes.length - 1 && visibleGoToNextCss]}>
              <a target="_blank" rel="noopener" href={lightboxes[Math.min(currentLightboxIndex + 1, lightboxes.length - 1)].src} onClick={handleClickGoToNext}>
                <FontAwesomeIcon icon={faAngleRight} fixedWidth />
              </a>
            </div> */}
          </div>

          {lightboxes.length > 1 && (
            <div css={thumbnailsCss} onClick={stopPropagation} ref={setupThumbnails}>
              <div css={thumbnailsWrapperCss}>
                {lightboxes.map((lightbox, index) => (
                  <a
                    title={lightbox.title}
                    css={[thumbnailCss, lightbox.src === lightboxes[currentLightboxIndex].src && selectedThumbnailCss]}
                    key={index}
                    href={lightbox.src}
                    target="_blank"
                    rel="noopener"
                    onClick={handleClickThumbnail}
                    data-index={index}
                  >
                    {(lightbox.thumbnail.fixed || lightbox.thumbnail.fluid) && (
                      <img
                        alt={lightbox.title}
                        title={lightbox.title}
                        src={(lightbox.thumbnail.fixed || lightbox.thumbnail.fluid)?.src}
                        srcSet={(lightbox.thumbnail.fixed || lightbox.thumbnail.fluid)?.srcSet}
                        decoding="async"
                      />
                    )}

                    {!lightbox.thumbnail.fixed && !lightbox.thumbnail.fluid && (
                      <img
                        alt={lightbox.title}
                        title={lightbox.title}
                        src={lightbox.thumbnail.src}
                        decoding="async"
                      />
                    )}
                  </a>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </Portal>
  );
};
