import {GatsbyLinkProps, Link as GatsbyLink} from 'gatsby';
import {OutboundLink} from 'gatsby-plugin-google-gtag';
import {forwardRef, useContext, useMemo} from 'react';
import {AllPagesContext} from '../../contexts/AllPagesContext';
import {CurrentPageContext} from '../../contexts/CurrentPageContext';
import {extractPageFromUrl} from './extractPageFromUrl';
import {extractPathFromUrl} from './extractPathFromUrl';

if (!process.env.SITE_URL) {
  throw new Error(`${__filename} Missing process.env.SITE_URL`);
}

export type Props = React.AnchorHTMLAttributes<HTMLAnchorElement> & Pick<GatsbyLinkProps<any>, 'replace'>;

export const Link = forwardRef<HTMLAnchorElement, Props>((props: Props, ref) => {
  const {href, replace, ...anchorProps} = props;

  const {same} = useContext(CurrentPageContext);
  const {pages, paths, pagesByPage, pagesByPath} = useContext(AllPagesContext);

  const pageUrl = useMemo(
    () => {
      if (href) {
        if (pagesByPage[href]) {
          return pagesByPage[href].path;
        }

        if (pagesByPath[href]) {
          return href;
        }

        if (same.some((it) => it.path === href)) {
          return href;
        }

        const page = extractPageFromUrl(href, pages);

        if (page) {
          return href.replace(new RegExp(`^${page}`), pagesByPage[page].path);
        }

        const path = extractPathFromUrl(href, paths);

        if (path) {
          return href;
        }
      }
    },
    [pages, paths, pagesByPage, pagesByPath, href, same],
  );

  // const url = useMemo(
  //   () => {
  //     if (!pageUrl && href) {
  //       try {
  //         return new URL(href);
  //       } catch {
  //         if (/^\/[^/]+/.test(href)) {
  //           return new URL(`${process.env.SITE_URL}${href}`);
  //         }
  //       }
  //     }
  //   },
  //   [href, href],
  // );

  if (pageUrl) {
    return (
      <GatsbyLink
        {...anchorProps}
        ref={ref as any}
        replace={replace}
        to={pageUrl}
      />
    );
  }

  // const href = useMemo(
  //   () => (
  //     typeof hrefFromProps === 'string'
  //       ? parse(hrefFromProps) as Exclude<Props['href'], string>
  //       : hrefFromProps
  //   ),
  //   [hrefFromProps],
  // );

  // if (typeof href === 'undefined') {
  //   return (
  //     <a ref={ref} {...anchorProps} />
  //   );
  // }

  return (
    <OutboundLink ref={ref as any} href={href} {...anchorProps} />
  );

  // if (isExternalLink(href)) {
  //   return (
  //     <OutboundLink ref={ref as any} href={href} {...anchorProps} />
  //   );
  // }

  // // Link opened with external app
  // if (url?.protocol && !/^https?:$/.test(url.protocol)) {
  //   // TODO: track custom GA event https://github.com/gatsbyjs/gatsby/blob/9d304ed3c63069a976bc4be85df2e876512149e0/packages/gatsby-plugin-google-analytics/src/index.js#L40
  //   return (
  //     <a ref={ref} href={href} {...anchorProps} />
  //   );
  // }

  // if (anchorProps.download) {
  //   // TODO: track custom GA event https://github.com/gatsbyjs/gatsby/blob/9d304ed3c63069a976bc4be85df2e876512149e0/packages/gatsby-plugin-google-analytics/src/index.js#L40
  //   return (
  //     <a ref={ref} href={href} {...anchorProps} />
  //   );
  // }

  // // Link to a file
  // // if (/\/.+\.\w+$/.test(href)) {
  // //   // TODO: track custom GA event https://github.com/gatsbyjs/gatsby/blob/9d304ed3c63069a976bc4be85df2e876512149e0/packages/gatsby-plugin-google-analytics/src/index.js#L40
  // //   return (
  // //     <a ref={ref} href={href} {...anchorProps} />
  // //   );
  // // }

  // return (
  //   <a ref={ref} href={href} {...anchorProps} />
  // );
});

Link.whyDidYouRender = true;
