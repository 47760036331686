import {css} from '@emotion/core';
import {useCallback} from 'react';
import {colors} from '../../styles/colors';

const overlayCss = css({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: colors.overlay.background,
  overflow: 'hidden',
  zIndex: 100,
});

export type Props = {
  show?: boolean,
  onClick?(): void,
  className?: string,
};

export const Overlay = (props: Props) => {
  const {onClick, className} = props;

  const setupOverlay = useCallback(
    (el: HTMLDivElement | null) => {
      el?.addEventListener(
        'wheel',
        (e) => {
          e.preventDefault();
        },
        {passive: false},
      );

      el?.addEventListener(
        'touchmove',
        (e) => {
          e.preventDefault();
        },
        {passive: false},
      );
    },
    [],
  );

  return (
    <div css={overlayCss} onClick={onClick} ref={setupOverlay} className={className} />
  );
};
