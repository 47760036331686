// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-cms-pages-benefits-of-a-static-website-page-en-yml": () => import("./../../../src/cms/pages/benefits-of-a-static-website/page.en.yml" /* webpackChunkName: "component---src-cms-pages-benefits-of-a-static-website-page-en-yml" */),
  "component---src-cms-pages-benefits-of-a-static-website-page-it-yml": () => import("./../../../src/cms/pages/benefits-of-a-static-website/page.it.yml" /* webpackChunkName: "component---src-cms-pages-benefits-of-a-static-website-page-it-yml" */),
  "component---src-cms-pages-curriculum-vitae-page-en-yml": () => import("./../../../src/cms/pages/curriculum-vitae/page.en.yml" /* webpackChunkName: "component---src-cms-pages-curriculum-vitae-page-en-yml" */),
  "component---src-cms-pages-curriculum-vitae-page-it-yml": () => import("./../../../src/cms/pages/curriculum-vitae/page.it.yml" /* webpackChunkName: "component---src-cms-pages-curriculum-vitae-page-it-yml" */),
  "component---src-cms-pages-escape-shell-strings-tool-page-en-yml": () => import("./../../../src/cms/pages/escape-shell-strings-tool/page.en.yml" /* webpackChunkName: "component---src-cms-pages-escape-shell-strings-tool-page-en-yml" */),
  "component---src-cms-pages-escape-shell-strings-tool-page-it-yml": () => import("./../../../src/cms/pages/escape-shell-strings-tool/page.it.yml" /* webpackChunkName: "component---src-cms-pages-escape-shell-strings-tool-page-it-yml" */),
  "component---src-cms-pages-mobile-first-web-design-page-it-yml": () => import("./../../../src/cms/pages/mobile-first-web-design/page.it.yml" /* webpackChunkName: "component---src-cms-pages-mobile-first-web-design-page-it-yml" */),
  "component---src-cms-pages-responsive-web-design-page-it-yml": () => import("./../../../src/cms/pages/responsive-web-design/page.it.yml" /* webpackChunkName: "component---src-cms-pages-responsive-web-design-page-it-yml" */),
  "component---src-cms-pages-static-websites-page-it-yml": () => import("./../../../src/cms/pages/static-websites/page.it.yml" /* webpackChunkName: "component---src-cms-pages-static-websites-page-it-yml" */),
  "component---src-cms-pages-tools-page-en-yml": () => import("./../../../src/cms/pages/tools/page.en.yml" /* webpackChunkName: "component---src-cms-pages-tools-page-en-yml" */),
  "component---src-cms-pages-tools-page-it-yml": () => import("./../../../src/cms/pages/tools/page.it.yml" /* webpackChunkName: "component---src-cms-pages-tools-page-it-yml" */),
  "component---src-cms-posts-blog-2020-08-31-my-first-post-page-en-yml": () => import("./../../../src/cms/posts/blog/2020-08-31--my-first-post/page.en.yml" /* webpackChunkName: "component---src-cms-posts-blog-2020-08-31-my-first-post-page-en-yml" */),
  "component---src-cms-posts-blog-2020-08-31-my-first-post-page-it-yml": () => import("./../../../src/cms/posts/blog/2020-08-31--my-first-post/page.it.yml" /* webpackChunkName: "component---src-cms-posts-blog-2020-08-31-my-first-post-page-it-yml" */),
  "component---src-cms-posts-blog-2020-09-01-how-a-passion-for-cycling-begins-page-it-yml": () => import("./../../../src/cms/posts/blog/2020-09-01--how-a-passion-for-cycling-begins/page.it.yml" /* webpackChunkName: "component---src-cms-posts-blog-2020-09-01-how-a-passion-for-cycling-begins-page-it-yml" */),
  "component---src-cms-posts-blog-2020-09-16-short-panoramic-bike-ride-on-berici-hills-from-vicenza-page-en-yml": () => import("./../../../src/cms/posts/blog/2020-09-16--short-panoramic-bike-ride-on-berici-hills-from-vicenza/page.en.yml" /* webpackChunkName: "component---src-cms-posts-blog-2020-09-16-short-panoramic-bike-ride-on-berici-hills-from-vicenza-page-en-yml" */),
  "component---src-cms-posts-blog-2020-09-16-short-panoramic-bike-ride-on-berici-hills-from-vicenza-page-it-yml": () => import("./../../../src/cms/posts/blog/2020-09-16--short-panoramic-bike-ride-on-berici-hills-from-vicenza/page.it.yml" /* webpackChunkName: "component---src-cms-posts-blog-2020-09-16-short-panoramic-bike-ride-on-berici-hills-from-vicenza-page-it-yml" */),
  "component---src-cms-posts-blog-2020-10-07-how-difficult-buying-a-bicycle-is-page-it-yml": () => import("./../../../src/cms/posts/blog/2020-10-07--how-difficult-buying-a-bicycle-is/page.it.yml" /* webpackChunkName: "component---src-cms-posts-blog-2020-10-07-how-difficult-buying-a-bicycle-is-page-it-yml" */),
  "component---src-cms-posts-blog-2020-10-14-pasubio-circular-route-page-it-yml": () => import("./../../../src/cms/posts/blog/2020-10-14--pasubio-circular-route/page.it.yml" /* webpackChunkName: "component---src-cms-posts-blog-2020-10-14-pasubio-circular-route-page-it-yml" */),
  "component---src-cms-system-blog-index-page-en-yml": () => import("./../../../src/cms/system/blog-index/page.en.yml" /* webpackChunkName: "component---src-cms-system-blog-index-page-en-yml" */),
  "component---src-cms-system-blog-index-page-it-yml": () => import("./../../../src/cms/system/blog-index/page.it.yml" /* webpackChunkName: "component---src-cms-system-blog-index-page-it-yml" */),
  "component---src-cms-system-contact-confirmation-page-en-yml": () => import("./../../../src/cms/system/contact-confirmation/page.en.yml" /* webpackChunkName: "component---src-cms-system-contact-confirmation-page-en-yml" */),
  "component---src-cms-system-contact-confirmation-page-it-yml": () => import("./../../../src/cms/system/contact-confirmation/page.it.yml" /* webpackChunkName: "component---src-cms-system-contact-confirmation-page-it-yml" */),
  "component---src-cms-system-contact-page-en-yml": () => import("./../../../src/cms/system/contact/page.en.yml" /* webpackChunkName: "component---src-cms-system-contact-page-en-yml" */),
  "component---src-cms-system-contact-page-it-yml": () => import("./../../../src/cms/system/contact/page.it.yml" /* webpackChunkName: "component---src-cms-system-contact-page-it-yml" */),
  "component---src-cms-system-index-page-en-yml": () => import("./../../../src/cms/system/index/page.en.yml" /* webpackChunkName: "component---src-cms-system-index-page-en-yml" */),
  "component---src-cms-system-index-page-it-yml": () => import("./../../../src/cms/system/index/page.it.yml" /* webpackChunkName: "component---src-cms-system-index-page-it-yml" */),
  "component---src-cms-system-not-found-page-en-yml": () => import("./../../../src/cms/system/not-found/page.en.yml" /* webpackChunkName: "component---src-cms-system-not-found-page-en-yml" */),
  "component---src-cms-system-not-found-page-it-yml": () => import("./../../../src/cms/system/not-found/page.it.yml" /* webpackChunkName: "component---src-cms-system-not-found-page-it-yml" */),
  "component---src-cms-system-privacy-policy-page-en-yml": () => import("./../../../src/cms/system/privacy-policy/page.en.yml" /* webpackChunkName: "component---src-cms-system-privacy-policy-page-en-yml" */),
  "component---src-cms-system-privacy-policy-page-it-yml": () => import("./../../../src/cms/system/privacy-policy/page.it.yml" /* webpackChunkName: "component---src-cms-system-privacy-policy-page-it-yml" */),
  "component---src-cms-system-search-page-en-yml": () => import("./../../../src/cms/system/search/page.en.yml" /* webpackChunkName: "component---src-cms-system-search-page-en-yml" */),
  "component---src-cms-system-search-page-it-yml": () => import("./../../../src/cms/system/search/page.it.yml" /* webpackChunkName: "component---src-cms-system-search-page-it-yml" */)
}

