import createEmotionCache from '@emotion/cache';
import {CacheProvider} from '@emotion/core';
import {shouldPolyfill as shouldPolyfillDateTimeFormat} from '@formatjs/intl-datetimeformat/should-polyfill';
import {shouldPolyfill as shouldPolyfillGetCanonicalLocales} from '@formatjs/intl-getcanonicallocales/should-polyfill';
import {shouldPolyfill as shouldPolyfillListFormat} from '@formatjs/intl-listformat/should-polyfill';
import {shouldPolyfill as shouldPolyfillNumberFormat} from '@formatjs/intl-numberformat/should-polyfill';
import {shouldPolyfill as shouldPolyfillPluralRules} from '@formatjs/intl-pluralrules/should-polyfill';
import {globalHistory} from '@reach/router';
import codegen from 'babel-plugin-codegen/macro';
import React from 'react';
import {Cookies} from 'react-cookie';
import ReactDOM from 'react-dom';
import {Helmet} from 'react-helmet';
import {BehaviorSubject} from 'rxjs';
import {UserConsentProvider} from './src/contexts/UserConsentProvider';
import {googleFonts} from './src/styles/fonts';
import {trackPageView} from './src/utils/tracking/trackPageView';

if (!process.env.ACCEPT_COOKIES_COOKIE) {
  throw new Error('Missing process.env.ACCEPT_COOKIES_COOKIE');
}

if (!process.env.GOOGLE_ANALYTICS_TRACKING_ID) {
  throw new Error('Missing process.env.GOOGLE_ANALYTICS_TRACKING_ID');
}

const cookies = new Cookies();

const userConsentSubject = new BehaviorSubject(
  cookies.get(process.env.ACCEPT_COOKIES_COOKIE)
    ? 'allowed'
    : 'unknown',
);

const polyfillPromises = [];

if (shouldPolyfillNumberFormat()) {
  polyfillPromises.push(codegen`
    const supportedLocales = require('./i18n/supported-locales.json');

    const importStatements = supportedLocales
      .map((locale) => \`import('@formatjs/intl-numberformat/locale-data/\${locale}')\`);

    module.exports = \`import('@formatjs/intl-numberformat/polyfill').then(() => Promise.all([
      \${importStatements.join(', ')},
    ]));\`;
  `);
}

if (shouldPolyfillDateTimeFormat()) {
  polyfillPromises.push(codegen`
    const supportedLocales = require('./i18n/supported-locales.json');

    const importStatements = supportedLocales
      .map((locale) => \`import('@formatjs/intl-datetimeformat/locale-data/\${locale}')\`);

    module.exports = \`import('@formatjs/intl-datetimeformat/polyfill').then(() => Promise.all([
      \${importStatements.join(', ')},
    ]));\`;
  `);
}

if (shouldPolyfillPluralRules()) {
  polyfillPromises.push(codegen`
    const supportedLocales = require('./i18n/supported-locales.json');

    const importStatements = supportedLocales
      .map((locale) => \`import('@formatjs/intl-pluralrules/locale-data/\${locale}')\`);

    module.exports = \`import('@formatjs/intl-pluralrules/polyfill').then(() => Promise.all([
      \${importStatements.join(', ')},
    ]));\`;
  `);
}

if (shouldPolyfillListFormat()) {
  polyfillPromises.push(codegen`
    const supportedLocales = require('./i18n/supported-locales.json');

    const importStatements = supportedLocales
      .map((locale) => \`import('@formatjs/intl-listformat/locale-data/\${locale}')\`);

    module.exports = \`import('@formatjs/intl-listformat/polyfill').then(() => Promise.all([
      \${importStatements.join(', ')},
    ]));\`;
  `);
}

if (shouldPolyfillGetCanonicalLocales()) {
  polyfillPromises.push(import('@formatjs/intl-getcanonicallocales/polyfill'));
}

if (!Element.prototype.scrollTo) {
  polyfillPromises.push(import('element-scroll-polyfill'));
}

// if (!window.EventSource && process.env.NODE_ENV === 'development') {
//   polyfillPromises.push(
//     import('event-source-polyfill').then(({EventSource}) => {
//       window.EventSource = EventSource;
//     }),
//   );
// }

/** @type {import('@sentry/react') | undefined} */
let sentry;

/** @type {import('gatsby').GatsbyBrowser['onClientEntry']} */
export const onClientEntry = async () => {
  if (process.env.SENTRY_DSN) {
    import('@sentry/react').then((sentryImport) => {
      sentry = sentryImport;

      sentry.init({
        dsn: process.env.SENTRY_DSN,
      });
    });
  }

  if (process.env.WHY_DID_YOU_RENDER) {
    // eslint-disable-next-line global-require, import/no-extraneous-dependencies
    const {default: whyDidYouRender} = await import('@welldone-software/why-did-you-render');

    whyDidYouRender(React, {
      // trackAllPureComponents: true
    });
  }

  // Leave this before the cookie check so we can extend the
  // expiration time
  userConsentSubject.subscribe({
    next: (userConsent) => {
      if (userConsent === 'allowed') {
        delete window[`ga-disable-${process.env.GOOGLE_ANALYTICS_TRACKING_ID}`];

        const duration = 365 * 24 * 60 * 60 * 1000;

        cookies.set(process.env.ACCEPT_COOKIES_COOKIE, true, {
          path: '/',
          expires: new Date(Date.now() + duration),
          sameSite: 'strict',
        });

        trackPageView(globalHistory.location);
      } else {
        cookies.remove(process.env.ACCEPT_COOKIES_COOKIE, {
          path: '/',
        });

        window[`ga-disable-${process.env.GOOGLE_ANALYTICS_TRACKING_ID}`] = true;
      }
    },
  });

  await Promise.all(polyfillPromises);
};

/** @type {import('gatsby').GatsbyBrowser['shouldUpdateScroll']} */
export const shouldUpdateScroll = (args) => {
  const {routerProps, prevRouterProps} = args;

  if (routerProps.location.pathname === prevRouterProps?.location.pathname) {
    return (
      !routerProps.location.state?.disableScrollUpdate
      && !prevRouterProps.location.state?.disableScrollUpdate
    );
  }

  return true;
};

/** @type {import('gatsby').GatsbyBrowser['wrapRootElement']} */
export const wrapRootElement = ({element}) => {
  const emotionCache = createEmotionCache();

  let googleFontsLink = null;

  if (process.env.NODE_ENV === 'development') {
    // https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;1,200;1,500&family=Mukta:wght@400;500&display=swap
    const googleFontsFamily = googleFonts
      .map((font) => {
        const regular = font.styles.map((style) => `0,${style}`);
        const italic = font.styles.map((style) => `1,${style}`);

        return `family=${font.name.replace(' ', '+')}:ital,wght@${regular.concat(italic).join(';')}`;
      })
      .join('&');

    const googleFontsUrl = `//fonts.googleapis.com/css2?${googleFontsFamily}&display=swap`;

    googleFontsLink = (
      <Helmet>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href={googleFontsUrl}
          rel="stylesheet"
          type="text/css"
        />
      </Helmet>
    );
  }

  return (
    <CacheProvider value={emotionCache}>
      <UserConsentProvider userConsentSubject={userConsentSubject}>
        {googleFontsLink}

        {element}
      </UserConsentProvider>
    </CacheProvider>
  );
};

/** @type {import('gatsby').GatsbyBrowser['replaceHydrateFunction']} */
export const replaceHydrateFunction = () => (element, container, callback) => {
  ReactDOM.render(element, container, callback);
};

/** @type {import('gatsby').GatsbyBrowser['onRouteUpdate']} */
export const onRouteUpdate = ({location}, pluginOptions = {}) => {
  if (typeof location !== 'object') {
    sentry?.captureMessage(`Location is not an object! [location:${location}] [window.location:${JSON.stringify(window.location)}]`, 'error');
  }
};
