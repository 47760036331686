if (!process.env.SITE_URL) {
  throw new Error('Missing process.env.SITE_URL');
}

export const isExternalLink = (link: string): boolean => {
  try {
    const url = new URL(link);

    if (/^https?:$/.test(url.protocol)) {
      const siteUrl = new URL(process.env.SITE_URL!);

      return url.protocol !== siteUrl.protocol || url.host !== siteUrl.host;
    }
  } catch {
    if (link.startsWith('//')) {
      const {protocol} = new URL(process.env.SITE_URL!);

      return isExternalLink(`${protocol}:${link}`);
    }
  }

  return false;
};
